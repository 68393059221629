import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const CabinAdvantageGas = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 80 80" {...rest}>
        <g>
            <path
                d="M33,16.8C33,16.8,33,16.8,33,16.8l2.8-4.5c1.5-2.3,4.6-3,6.9-1.6c0.6,0.4,1.2,0.9,1.6,1.6h0l6.5,10.3l-6.4,4
		c-1.2,0.7-1.5,2.3-0.8,3.4c0.4,0.6,0.9,1,1.6,1.1l17.2,4c1.3,0.3,2.7-0.5,3-1.9c0,0,0,0,0,0l4-17.2c0.3-1.3-0.5-2.7-1.9-3
		c-0.6-0.1-1.3,0-1.9,0.3l-6.4,4L52.7,7C48.3,0,39.1-2.1,32,2.3c-1.9,1.2-3.6,2.8-4.8,4.8l-2.8,4.5c-0.5,0.9-0.3,2,0.6,2.6
		c0,0,0,0,0,0l5.3,3.3C31.3,18,32.4,17.7,33,16.8z"
            />
            <path
                d="M28.8,40.9l-4-17.2c0,0,0,0,0,0c-0.3-1.3-1.7-2.2-3-1.9l-17.2,4C4,26,3.4,26.3,3.1,26.9c-0.7,1.2-0.4,2.7,0.8,3.4l6.4,4
		L2.3,47.1C0.8,49.4,0,52.2,0,55c0,8.3,6.7,15,15,15h8.1c1,0,1.9-0.8,1.9-1.9v-6.2c0,0,0,0,0,0c0-1-0.8-1.9-1.9-1.9H15
		c-0.9,0-1.8-0.3-2.6-0.8c-2.3-1.5-3.1-4.5-1.6-6.9l7.9-12.7l6.4,4c0.6,0.3,1.2,0.5,1.9,0.3C28.3,43.6,29.1,42.3,28.8,40.9
		L28.8,40.9z"
            />
            <path
                d="M77.7,47.1l-4.3-6.9c-0.5-0.9-1.7-1.1-2.6-0.6c0,0,0,0,0,0l-5.3,3.3c-0.9,0.5-1.1,1.7-0.6,2.6c0,0,0,0,0,0l4.3,6.9
		c1.5,2.3,0.7,5.4-1.6,6.9C66.8,59.7,65.9,60,65,60H50v-7.5c0-1.4-1.1-2.5-2.5-2.5c-0.7,0-1.3,0.3-1.8,0.7L33.2,63.2
		c-1,1-1,2.6,0,3.5l12.5,12.5c1,1,2.6,1,3.5,0c0.5-0.5,0.7-1.1,0.7-1.8V70h15c8.3,0,15-6.7,15-15C80,52.2,79.2,49.4,77.7,47.1z"
            />
        </g>
    </Icon>
);

export default CabinAdvantageGas;
