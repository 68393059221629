import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const CabinHealth3 = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 70 80" {...rest}>
        <path
            d="M65,17.5c-2.8,0-5,2.2-5,5v11.2c0,0.7-0.6,1.2-1.2,1.2h-2.5c-0.7,0-1.2-0.6-1.2-1.2V10c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5
	v23.7c0,0.7-0.6,1.2-1.2,1.2h-2.5c-0.7,0-1.2-0.6-1.2-1.2V5c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5v28.7c0,0.7-0.6,1.2-1.2,1.2h-2.5
	c-0.7,0-1.2-0.6-1.2-1.2V10c0-2.8-2.2-5-5-5c-2.8,0-5,2.2-5,5v37.6l-3.7-5.1c-2.1-2.8-6-3.4-8.7-1.3c-2.7,2-3.4,5.9-1.4,8.7l19.6,27
	c1.4,1.9,3.7,3.1,6.1,3.1h30.9c3.5,0,6.5-2.4,7.3-5.8l4.1-17.6c0.5-2.2,0.8-4.4,0.8-6.6V22.5C70,19.7,67.7,17.5,65,17.5z M27.5,65
	c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C30,63.9,28.9,65,27.5,65z M27.5,50L27.5,50c-1.4,0-2.5-1.1-2.5-2.5
	s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C30,48.9,28.9,50,27.5,50z M37.5,70L37.5,70c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5
	c1.4,0,2.5,1.1,2.5,2.5C40,68.9,38.9,70,37.5,70z M37.5,55L37.5,55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C40,53.9,38.9,55,37.5,55z M47.5,60L47.5,60c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C50,58.9,48.9,60,47.5,60
	z M52.5,70L52.5,70c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C55,68.9,53.9,70,52.5,70z M57.5,50L57.5,50
	c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C60,48.9,58.9,50,57.5,50z"
        />
    </Icon>
);

export default CabinHealth3;
