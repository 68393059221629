import { ReactElement } from 'react';

import Icon, { IconProps } from '@components/atoms/Icon';

const CabinHealth2 = ({ size, ...rest }: IconProps): ReactElement => (
    <Icon size={size} viewBox="0 0 73.4 80" {...rest}>
        <g>
            <path
                d="M64.9,44.5c-0.7-1.3-2-2-3.4-2H45.7c-1.4,0-2.7,0.8-3.4,2l-8.1,14.9c-0.6,1.2-0.6,2.6,0,3.7L42.2,78c0.7,1.3,2,2,3.4,2
		h15.8c1.4,0,2.7-0.8,3.4-2l8.1-14.8c0.6-1.2,0.6-2.6,0-3.7L64.9,44.5z M65.3,62.8l-4.7,8.1c-0.6,1-1.6,1.6-2.7,1.6h-8.8
		c-1.1,0-2.2-0.6-2.7-1.6l-4.7-8.1c-0.6-1-0.6-2.2,0-3.2l4.7-8.1c0.6-1,1.6-1.6,2.7-1.6h8.8c1.1,0,2.2,0.6,2.7,1.6l4.7,8.1
		C65.9,60.7,65.9,61.9,65.3,62.8z"
            />
            <path
                d="M39.2,36.9L31.2,22c-0.7-1.3-2-2-3.4-2H12c-1.4,0-2.7,0.8-3.4,2L0.5,36.9c-0.6,1.2-0.6,2.6,0,3.7l8.1,14.8
		c0.7,1.3,2,2,3.4,2h15.8c1.4,0,2.7-0.8,3.4-2l8.1-14.8C39.9,39.5,39.9,38.1,39.2,36.9z"
            />
            <path
                d="M42.2,35.5c0.7,1.3,2,2,3.4,2h15.8c1.4,0,2.7-0.8,3.4-2l8.1-14.8c0.6-1.2,0.6-2.6,0-3.7L64.9,2c-0.7-1.3-2-2-3.4-2H45.7
		c-1.4,0-2.7,0.8-3.4,2l-8.1,14.9c-0.6,1.2-0.6,2.6,0,3.7L42.2,35.5z"
            />
        </g>
    </Icon>
);

export default CabinHealth2;
